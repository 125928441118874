import axios from 'axios'

export function getAllPerfilFirmas () {
  return axios.get('/maestros/perfilfirmas')
  .then(response => {
    return response.data
  })
}

export function updatePerfilFirmas (id, firma) {
  return axios.put('/maestros/perfilfirmas/' + id, firma)
  .then(response => {
      return response.data
    })
}

export function addPerfilFirmas (firma) {
  return axios.post('/maestros/perfilfirmas', firma)
  .then(response => {
    return response.data
  })
}

export function deletePerfilFirmas (id) {
  return axios.delete('/maestros/perfilfirmas/' + id)
  .then(response => {
      return response.data
    })
}

export function getOnePerfilFirmas (id) {
  return axios.get('/maestros/perfilfirmas/' + id)
  .then(response => {
      return response.data
    })
}

export function getLogsPerfilFirmas (id) {
  return axios.get('/maestros/perfilfirmas/audit/' + id)
  .then(response => {
      return response.data
    })
}

export default {
  getAllPerfilFirmas,
  updatePerfilFirmas,
  addPerfilFirmas,
  deletePerfilFirmas,
  getOnePerfilFirmas,
  getLogsPerfilFirmas,
}
